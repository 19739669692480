
import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { getImage } from "gatsby-plugin-image"
import Seo from "../../components/layout/seo"
import Hero from "../../components/Global/secondLevelHero/secondLevelHero"
import FullWidthText from "../../components/Global/fullWidthText/fullWidthText"
import Infographics from "../../components/Global/infographics/infographics"
import FullWidthImage from "../../components/Global/fullWidthImage/fullWidthImage"
import FullWidthCTA from "../../components/Global/fullWidthCta/fullWidthCta"
import Footnotes from "../../components/Global/footnotes/footnotes"
import Footer from "../../components/layout/footer"

const ImmunotherapyPage = ({ data }) => {

  const content = data.allContentfulImmunotherapyPage.nodes[0];

  let heroData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
    backPos: "top right"
  }

  let introductionText = content.infographicIntroduction
  let infographicsData = content.infographics
  let outroText = content.infographicOutro

  let fullWidthCtaData = {
    title: content.relatedPages.title,
    buttons: content.relatedPages.buttons,
    customClass: "mt-5"
  }

  let fullWidthBannerData = {
    imageTitle: content.fullWidthBanner.image.title,
    image: getImage(content.fullWidthBanner.image),
  }
  
  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: content.footerData,
  }

  return (
    <Layout>
      <Seo title="How immunotherapy works" />
      <Hero data={heroData}/>
      <FullWidthText data={introductionText} />
      <Infographics data={infographicsData} />
      <FullWidthText data={outroText} />
      <FullWidthCTA data={fullWidthCtaData} />
      <FullWidthImage data={fullWidthBannerData} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulImmunotherapyPage {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        infographicIntroduction {
          childMarkdownRemark {
            html
          }
        }
        infographics {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        infographicOutro {
          childMarkdownRemark {
            html
          }
        }
        fullWidthBanner {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        relatedPages {
          title {
            childMarkdownRemark {
              html
            }
          }
          buttons {
            buttonText {
              childMarkdownRemark {
                html
              }
            }
            buttonUrl
          }
        }
        footerData {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          id
        }
        jobCodeDate
      }
    }
  }
`

export default ImmunotherapyPage