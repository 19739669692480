// extracted by mini-css-extract-plugin
export var active = "infographics-module--active--44f24";
export var bigWhite = "infographics-module--big-white--f1380";
export var blue = "infographics-module--blue--6b5db";
export var blueBg = "infographics-module--blue-bg--b05ac";
export var center = "infographics-module--center--b332b";
export var cookie__customise = "infographics-module--cookie__customise--ee6ea";
export var cookie__customise__buttons = "infographics-module--cookie__customise__buttons--c7638";
export var cookie__customise__buttons__allow = "infographics-module--cookie__customise__buttons__allow--449e8";
export var cookie__customise__buttons__disable = "infographics-module--cookie__customise__buttons__disable--43700";
export var cookie__customise__buttons__save = "infographics-module--cookie__customise__buttons__save--efc01";
export var cookie__customise__close = "infographics-module--cookie__customise__close--ad27a";
export var cookie__customise__content = "infographics-module--cookie__customise__content--06a22";
export var cookie__customise__content__title = "infographics-module--cookie__customise__content__title--bdea1";
export var cookie__customise__overlay = "infographics-module--cookie__customise__overlay--cbb39";
export var cookie__popup = "infographics-module--cookie__popup--d4e2f";
export var cookie__popup__button = "infographics-module--cookie__popup__button--6b1b8";
export var cookie__popup__close = "infographics-module--cookie__popup__close--b9100";
export var cookie__popup__content = "infographics-module--cookie__popup__content--5c4dc";
export var cookie__popup__text = "infographics-module--cookie__popup__text--c21e9";
export var decreaseHeight = "infographics-module--decrease-height--52b75";
export var embed_responsive = "infographics-module--embed_responsive--3deba";
export var embed_responsive_16by9 = "infographics-module--embed_responsive_16by9--f75c2";
export var embed_responsive_item = "infographics-module--embed_responsive_item--a6e4c";
export var flexUl = "infographics-module--flex-ul--b2963";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "infographics-module--fullWidthCta-module--fullWidthCta--fdc73--891b7";
export var fullWidthTextModuleIntroText2b0e4 = "infographics-module--fullWidthText-module--introText--2b0e4--fac05";
export var header = "infographics-module--header--a381e";
export var iframeContainer = "infographics-module--iframe-container--70a86";
export var indicationSection = "infographics-module--indication-section--d4cc8";
export var infographics = "infographics-module--infographics--a8b91";
export var infographics__item = "infographics-module--infographics__item--b1419";
export var infographics__item__image = "infographics-module--infographics__item__image--31c13";
export var infographics__item__text = "infographics-module--infographics__item__text--e6c69";
export var left = "infographics-module--left--bd3cc";
export var overlay = "infographics-module--overlay--26715";
export var removeMobile = "infographics-module--remove-mobile--5ad39";
export var responsiveIframe = "infographics-module--responsive-iframe--58dec";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "infographics-module--secondLevelHero-module--banner__subTitle--8b2b5--ac875";
export var singleTimestamp = "infographics-module--single-timestamp--26fb8";
export var slider = "infographics-module--slider--04a5c";
export var small = "infographics-module--small--12810";
export var standard = "infographics-module--standard--0b904";
export var switcher = "infographics-module--switcher--52674";
export var timestamps = "infographics-module--timestamps--041a0";
export var videoArea = "infographics-module--videoArea--b2e8a";
export var videoResource = "infographics-module--video-resource--ae0f2";
export var videoTime = "infographics-module--videoTime--d9313";
export var video__iframe = "infographics-module--video__iframe--3aa8d";
export var yell = "infographics-module--yell--2e825";