import React from "react"
import * as styles from "./infographics.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function Infographics({ data }) {
  const InfoGraphics = data.map(item => {

    const image = getImage(item.image)

    return (
      <div className={styles.infographics__item}>
        <div className={styles.infographics__item__image}>
          <GatsbyImage image={image} />
        </div>

        <div className={styles.infographics__item__text} dangerouslySetInnerHTML={{ __html: item.content.childMarkdownRemark.html }} />
      </div>
    )
  });

  return (
    <div className={styles.infographics}>
      {InfoGraphics}
    </div>
  )
}

export default Infographics